

import axiosInstance from "../interceptor";
import { CHATGPT_API_URL, GATEWAY_API_URL } from "../api";


//const api = '/api/v1/combinationSearch';
const api = '/api/combinationSearchService/api/v1/combinationSearch';

export interface CombinationSearchSendData {
  searchTerm?: string;
  sessionId: string | null;
  courseId?: string;
  audioName?: string;
  onlySearch?: string;
  searchId?: string;
  time?: string;
  articleId?: string;
  customer: string | null;
  modelName: string | null;
  numberOfSearchResults: string | null;
  bestResultMethod: string | null;
  longContext: string | null;
  runType: string;
  answerDataId: string;

}

export const combinationSearch = async (sendData: CombinationSearchSendData) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/getCombinationSearch`, sendData);
}
export const searchBySessionId = async (sessionId: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}/getSearchBySessionId/${sessionId}`);
}
export const searchByCourseId = async (userId: string | null, courseId: string, searchId: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/getSearchByCourseId`, {
    userIdFromList: userId,
    courseId,
    searchId
  });
}
export const searchByCourseIdWithAnswerDataId = async (userId: string | null, courseId: string, searchId: string, answerDataId: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/getSearchByCourseId`, {
    answerDataId,
    userIdFromList: userId,
    courseId,
    searchId
  });
}
export const getQuestionArchive = async (courseId: string) => {
  return axiosInstance.get(`${GATEWAY_API_URL}${api}/getQuestionArchiveByCourseId/${courseId}`);
}
export const deleteSearch = async (courseId: string, searchId: string, answerDataId: string | null) => {
  return axiosInstance.delete(`${GATEWAY_API_URL}${api}/deleteSearchByArticleIdAndSearchId/${courseId}/${searchId}/${answerDataId}`);
}
export const deleteMovie = async (courseId: string, searchId: string, searchIdMovie: string, articleId: string) => {
  return axiosInstance.delete(`${GATEWAY_API_URL}${api}/deleteMovieByArticleIdAndSearchId/${courseId}/${searchId}/${searchIdMovie}/${articleId}`);
}
export const deleteAnswerAndQuestionByQuestionId = async (courseId: string, searchId: string, questionId: string) => {
  return axiosInstance.delete(`${GATEWAY_API_URL}${api}/deleteAnswerAndQuestionByQuestionId/${courseId}/${searchId}/${questionId}`);
}
export const addToGlobalFAQ = async (answerDataId: string, userId: string, courseId: string, searchId: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/addToGlobalFAQ`, {
    answerDataId,
    userId,
    courseId,
    searchId
  });
}

export const addToPrivateFAQ = async (answerDataId: string, userId: string, courseId: string, searchId: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/addToPrivateFAQ`, {
    answerDataId,
    userId,
    courseId,
    searchId
  });
}

export const getAllGlobalFAQs = async (userId: string | null, courseId: string, searchId: string, answerDataId: string | null) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/getAllGlobalFAQs`, {
    userIdFromList: userId,
    courseId,
    searchId,
    answerDataId
  });
}

export const getAllPrivateFAQs = async (userId: string, courseId: string, searchId: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/getAllPrivateFAQs`, {
    userId,
    courseId,
    searchId
  });
}
export const removeFromGlobalFAQ = async (answerDataId: string, userId: string, courseId: string, searchId: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/removeFromGlobalFAQ`, {
    answerDataId,
    userId,
    courseId,
    searchId
  });
}

export const removeFromPrivateFAQ = async (answerDataId: string, userId: string, courseId: string, searchId: string) => {
  return axiosInstance.post(`${GATEWAY_API_URL}${api}/removeFromPrivateFAQ`, {
    answerDataId,
    userId,
    courseId,
    searchId
  });
}