import React, { useState, useEffect, useRef } from 'react';
import { Tooltip, Modal, Typography, Box, useTheme, Paper, Stack, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import { Semantic, Index } from './configuration/Interface';
import { fetchQuestionDelete } from '../../features/combinationSearchSlice';
import HtmlBoxInteract from './mainComponenets/HtmlBoxInteract';
import { createMonitoringDeleteAnswerQuestion } from '../../api/serverApis/monitoringApi';
interface AnswerProps {
  item: {
    question: string;
    answer: string;
    questionId: string;
  };
  data: Semantic | Index;
  dontShowDeleteButton?: boolean;
}

const VideoAnswer: React.FC<AnswerProps> = ({ item, data, dontShowDeleteButton }) => {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [openVideoInteract, setOpenVideoInteract] = useState(false);

  useEffect(() => {
    if (iframeRef.current) {
      const doc = iframeRef.current.contentDocument;
      const iframe = iframeRef.current;
      const iframeDoc = iframe.contentDocument;
      if (doc) {
        doc.open();
        doc.write(item.answer);
        doc.close();
      }
      if (iframeDoc) {
        const styleElement = iframeDoc?.createElement('style');
        styleElement.innerHTML = `body { background-color: ${theme.palette.background.default}; margin: 0; }`;
        iframeDoc?.head.appendChild(styleElement);
      }
      handleIframeLoad();
    }
  }, [item.answer]);

  const handleIframeLoad = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.style.height = iframe.contentWindow.document.documentElement.scrollHeight + 'px';
    }
  };
  const handleSearchDelete = () => {
    dispatch(fetchQuestionDelete({ courseId: data.courseId, searchId: data.searchId, questionId: item.questionId }))
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = () => {
    dispatch(fetchQuestionDelete({ courseId: data.courseId, searchId: data.searchId, questionId: item.questionId }));
    createMonitoringDeleteAnswerQuestion(data.courseId, data.searchId, item.questionId, item.question);
    setOpen(false); // Close the dialog after confirming deletion
  };
  const handleCloseVideoInteract = () => {
    setOpenVideoInteract(false);
  };
  const handleImageClick = () => {
    setOpenVideoInteract(true);
  };
  const isHebrew = (text: string) => /[\u0590-\u05FF\uFB1D-\uFB4F\uFB50-\uFDFF]/.test(text);
  return (
    <>
      <Paper elevation={3} sx={{ width: "100%", borderRadius: '20px', padding: theme.spacing(2), margin: 'auto', marginTop: theme.spacing(3) }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box sx={{
            padding: theme.spacing(1),
            borderRadius: '50%',
            border: `2px solid ${theme.palette.primary.main}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: theme.spacing(2),
          }}>
            <Tooltip title="View the Answer">
              <IconButton
                sx={{
                  color: theme.palette.info.main,
                }}
                onClick={handleImageClick}
              >
                <QuestionMarkIcon color="primary" sx={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>
          </Box>
          <Typography variant="h5" color="textSecondary" gutterBottom sx={{ fontSize: '1rem', direction: isHebrew(item.question) ? 'rtl' : 'ltr' }}>
            {item.question}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="flex-start" spacing={2} sx={{ marginTop: theme.spacing(4), direction: isHebrew(item.answer) ? 'rtl' : 'ltr' }}>
          <Box sx={{ width: '100%' }}>
            <iframe ref={iframeRef} style={{ width: '100%', border: 'none' }} onLoad={handleIframeLoad} />
          </Box>
        </Stack>
        {!dontShowDeleteButton && <Tooltip title="Delete the question and answer">
          <IconButton
            sx={{
              position: 'relative',
              top: theme.spacing(0),
              right: theme.spacing(1),
              color: theme.palette.info.main,
              height: '1px',
            }}
            onClick={handleClickOpen}
          >
            <DeleteIcon sx={{ fontSize: '20px', }} />
          </IconButton>
        </Tooltip>}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm Deletion"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this question and its answer?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleConfirmDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
      <Modal
        open={openVideoInteract}
        onClose={handleCloseVideoInteract}
        sx={{
          // This centers the modal, adjust positioning as needed
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1400
        }}
      >
        <HtmlBoxInteract question={item.question} answer={item.answer} onClose={handleCloseVideoInteract} />
      </Modal>
    </>
  );
};

export default VideoAnswer;
