import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import 'storm-react-diagrams/dist/style.min.css';
import App from './App';

const root = createRoot(document.getElementById('root')!);
root.render(
  //<React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  //</React.StrictMode>,

);


