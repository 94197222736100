import React, { useState } from 'react';
import { Avatar, Menu, MenuItem, Tooltip, IconButton, styled, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { menuPermissions } from '../middlewareComponents/AuthPolicy'; // Ensure the correct import path
import { logout } from '../../features/userSlice';
import { RootState, AppDispatch } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
// Create a styled avatar component for custom styling
const CustomAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: '#5D61AA',
  border: '2px solid #8a8db7',
  color: 'white',
  '&:hover': {
    backgroundColor: '#8a8db7',
    color: 'black',
    border: '2px solid #5D61AA'
  },
}));
const SupportMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: '#f0f8ff',
  color: '#1a73e8',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#e3f2fd',
    color: '#0b5ed7',
  },
}));
interface HeaderProps {
  userName: string;
}
const AvatarMenuComponent: React.FC<HeaderProps> = ({ userName }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [supportDialogOpen, setSupportDialogOpen] = useState(false);
  const permissions = menuPermissions(); // Fetch permissions from localStorage
  const wahtsappSupportGroup = 'https://chat.whatsapp.com/ENvXIDiVlwR1uNrXR1qcuB';
  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOptionClick = (path: string) => {
    handleClose();
    if (path === 'logout') {
      dispatch(logout());
      window.location.href = '/login';
    }
    if (path === 'home') {
      window.location.href = '/home';
    }
    else {
      navigate(path);
    }
  };
  const handleSupportClick = () => {
    setSupportDialogOpen(true);
  };

  const closeSupportDialog = () => {
    setSupportDialogOpen(false);
  };

  const openSupportLink = () => {
    window.open(wahtsappSupportGroup, '_blank');
    closeSupportDialog();
  };
  const userInitial = userName.charAt(0).toUpperCase();

  // Map permissions to menu items
  const menuItems = permissions ? Object.keys(permissions).map(pageName => (
    <MenuItem key={pageName} onClick={() => handleMenuOptionClick(`/${pageName.toLowerCase()}`)}>{permissions[pageName]}</MenuItem>
  )) : [];

  return (
    <>
      <Tooltip title={userName}>
        <IconButton onClick={handleAvatarClick}>
          <CustomAvatar>{userInitial}</CustomAvatar>
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* {menuItems} */}
        <MenuItem onClick={() => handleMenuOptionClick('home')}>Home</MenuItem>
        <SupportMenuItem onClick={handleSupportClick}>Support Group</SupportMenuItem>
        <MenuItem onClick={() => handleMenuOptionClick('logout')}>Log Out</MenuItem>
      </Menu>
      {/* Support Dialog */}
      <Dialog open={supportDialogOpen} onClose={closeSupportDialog}>
        <DialogTitle>Support Group for Xarcam Platform</DialogTitle>
        <DialogContent>
          <Typography>This is a WhatsApp group for support related to the Xarcam platform.</Typography>
          <Typography>Open Group or copy link: {wahtsappSupportGroup}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSupportDialog}>Cancel</Button>
          <Button onClick={openSupportLink} color="primary" variant="contained">Open Group</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AvatarMenuComponent;
