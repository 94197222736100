import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { Login } from './pages/Login';
import { SignUp } from './pages/SignUp';
import VideosSearchPage from './pages/VideosSearchPage';
import ManagerPage from './pages/ManagerPage';
import ProtectedRoute from './ProtectedRoute';
import HomePage from './pages/HomePage';
import CoursePage from './pages/CoursePage';
import CourseSelectedPage from './pages/CourseSelectedPage';
import CourseContent from './pages/CourseContentPage';
import ManualPage from './pages/ManualPage';
import ManagementTocPage from './components/managmentCourse/managmentTOC/ManagementTocPage';
import ManagementTocPage2 from './components/managmentCourse/managmentTOC/ManagementTocPage2';
import MonitoringPage from './pages/MonitoringPage';
import { ForgotPasswordForm } from './components/authentication/ForgotPassword';
import { ResetPasswordForm } from './components/authentication/ResetPasswordForm';
const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProtectedRoute><CourseSelectedPage /></ProtectedRoute>} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/ForgotPassword" element={<ForgotPasswordForm />} />
        <Route path="/ResetPassword" element={<ResetPasswordForm />} />
        {/* <Route path="/courseseleted" element={<ProtectedRoute><CourseSelectedPage /></ProtectedRoute>} /> */}
        <Route path="/home" element={<ProtectedRoute><CourseSelectedPage /></ProtectedRoute>} />
        {/* <Route path="/coursecontent/:courseId" element={<ProtectedRoute><CourseContent /></ProtectedRoute>} /> */}
        <Route path="/course" element={<ProtectedRoute><CoursePage /></ProtectedRoute>} />
        {/* <Route path="/admin/pages" element={<ProtectedRoute><HomePage /></ProtectedRoute>} /> */}
        {/* <Route path="/videossearch" element={<ProtectedRoute><VideosSearchPage /></ProtectedRoute>} /> */}
        {/* <Route path="/manager" element={<ProtectedRoute><ManagerPage /></ProtectedRoute>} /> */}
        {/* <Route path="/manual" element={<ProtectedRoute><ManualPage /></ProtectedRoute>} /> */}
        {/* <Route path="/managementtoc" element={<ManagementTocPage />} />
        <Route path="/managementtoc2" element={<ManagementTocPage2 />} /> */}
        <Route path="/monitoring" element={<ProtectedRoute requiredPermission="MonitoringPage.monitoring"><MonitoringPage /></ProtectedRoute>} />
        {/* Additional protected routes go here */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
